$(() => {

  // update documents
  $('.trainee-control input[type=checkbox]').on('click', function() {
    const documents = [];
    const form = $(this).closest('form');
    $('.trainee-control input[type=checkbox]').each(function() {
      const el = $(this);
      if (el.is(':checked')) {
        documents.push(el.attr('name'));
      }
    });

    // post data
    $.ajax({
      url: form.attr('action'),
      method: 'POST',
      data: JSON.stringify({ documents: documents.join(',') }),
      contentType: 'application/json',
    });
    
  });

});