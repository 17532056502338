// css
require('../scss/formateurs.scss');

// js

require('./utils/toasts');
require('./utils/common');
require('./utils/auto-toggle');

require('./formateurs/signature');
require('./formateurs/instructor');
require('./formateurs/trainee');
require('./formateurs/test');

$(() => {
  //
  const togglePassword = $('#password-toggle');
  
  togglePassword.on('click', () => {
    var type = $('#password').attr('type') === 'password' ? 'text' : 'password';
    $('#password').attr('type', type);
    togglePassword.children('i').toggleClass('fa-eye-slash fa-eye');
  });

  $('div.btn-div').on('click', function(e) {
    if (e.target.tagName === 'A') {
      return;
    }
    window.location.href = $(this).data('href');
  });

  //
  $('.modal.open').modal('show');

  //
  $('.disabled-on-click').on('click', function() {
    $(this).addClass('disabled');
  });
});
