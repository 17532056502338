
const SignaturePad  = require('signature_pad/dist/signature_pad.umd.js');

$(() => {

  const element = document.getElementById('modal-presence');
  if (!element) {
    return;
  }

  const iconSuccess = $('<i class="fal fa-badge-check fa-2xl text-light"></i>');
  const iconError   = $('<i class="fal fa-xmark fa-2xl text-light"></i>');

  let signatureBox;
  let signaturePad;
  
  const modal   = new bootstrap.Modal(element, {});
  
  // init signature Pad
  const initSignaturePad = () => {
    if (signaturePad) {
      // signature pad was already initalized: just clear it
      signaturePad.clear();
      return;
    }

    // create canvas to fill its parent
    const container = element.querySelector('#signature-pad');
    const width     = $(container).width();
    const height    = $(container).height();

    const canvasDiv = `<canvas id="signature-canvas" width="${width}" height="${height}"></canvas>`;
    $(container).append(canvasDiv);
    
    const canvas = element.querySelector('#signature-canvas');

    // Signature Pad
    signaturePad = new SignaturePad(canvas, {
      // It's Necessary to use an opaque color when saving image as JPEG;
      // this option can be omitted if only saving as PNG or SVG
      backgroundColor: 'rgb(255, 255, 255)'
    });
  };
  
  $(document).on('click', '.signature-box', function() {
    signatureBox = $(this);
    
    // copy attrs & open modal
    $(element).find('h5').text(signatureBox.data('title'));
    modal.show();

    // init signature pad
    initSignaturePad();

  });

  // erase signature
  $('#signature-erase').on('click', () => {
    signaturePad.clear();
  });

  // close modal
  $('#modal-close').on('click', () => {
    signaturePad.clear();
    signatureBox = null;
    modal.hide();
  });
  
  // save signature
  $('#signature-save').on('click', () => {
    modal.hide();

    if (!signaturePad || signaturePad.isEmpty()) {
      signatureBox = null;
      return;
    }

    const input = signatureBox.data('input');
    const url   = signatureBox.data('url');
    const attr  = signatureBox.data('attr');
    const day   = signatureBox.data('day');

    const dataURL = signaturePad.toDataURL('image/jpeg');

    if (input) {
      $(input).val(dataURL);
      const img = $('<img class="img-fluid" />');
      img.attr('src', dataURL);
      signatureBox.find('.nosignature').remove();
      signatureBox.find('img').remove();
      signatureBox.find('.box').append(img);
      return;
    }

    signatureBox.find('.spinner').show();

    const data = JSON.stringify({ day, [attr]: dataURL });

    $.ajax({
      url,
      method: 'POST',
      data,
      contentType: 'application/json',
      success: (res) => {
        window.location.reload();
      },
      error: (err) => {
        signatureBox.find('.spinner').hide();
        signatureBox.find('img').remove();
        alert('Erreur : la signature n\'a pas été enregistrée. Vérifiez votre connexion internet.');
      }
    });

  });
  

});

