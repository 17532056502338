
$(() => {
  // update criteria rating
  const updateCriteriaRating = (url, rating, $container) => {
    $.ajax({
      url: url,
      method: 'POST',
      data: JSON.stringify({ rating }),
      contentType: 'application/json',
      success: (res) => {
        if (res.themeRating !== undefined) {
          $('#theme_rating').text(res.themeRating);
        }
        if ($container) {
          $container.find('input').prop('disabled', false);
        }
      },
    });
  };
  $('.criteria-rating select').on('change', function() {
    const url = $(this).closest('.criteria-rating').data('url');
    updateCriteriaRating(url, $(this).val());
    $(this).find('.empty-option').remove();
  });

  // click is triggered from container for making click easier (larger space)
  $('.criteria-input-container').on('click', function(e, isTrigger) {
    const $input = $(this).find('input');
    const $container = $(this).parent();

    if (isTrigger || $input.prop('disabled')) {
      return ;
    }

    $container.find('input').prop('disabled', true);
    $input.trigger('click', true);

    const url = $(this).closest('.criteria-rating').data('url');
    const val = $input.val();
    updateCriteriaRating(url, val, $container);
  });

  // update evaluatoion point errors
  $('.evaluation_point input[type=checkbox]').on('click', function() {
    const url = $(this).data('url');
    const checked = $(this).is(':checked');
    $.ajax({
      url: url,
      method: 'POST',
      data: JSON.stringify({ checked }),
      contentType: 'application/json',
      success: () => {
        // location.reload();
      },
    });
  });

  //
  $('#scaleModal').on('show.bs.modal', function (event) {
    const url = $(event.relatedTarget).data('url');
    const scale = $(event.relatedTarget).data('scale');

    //
    $(this).find('a').each(function() {
      const scale = $(this).data('scale');
      $(this).attr('href', `${url}?scale=${scale}`);
    });

    //
    if (scale === 1) {
      $(this).find('#scale_2').hide();
      $(this).find('#scale_1').show();
    } else {
      $(this).find('#scale_1').hide();
      $(this).find('#scale_2').show();
    }
  });

  // update disqualifying errors
  $('.disqualifying-errors input[type=checkbox]').on('click', function() {
    const url     = $(this).data('url');
    const checked = $(this).is(':checked');
    const error   = $(this).val();
    $.ajax({
      url: url,
      method: 'POST',
      data: JSON.stringify({ checked, error }),
      contentType: 'application/json',
      success: () => {
        location.reload();
      },
    });
  });
});
