$(() => {

  // select file on button click
  $('form.upload-form .btn').on('click', function(e) {
    e.preventDefault();
    const form = $(this).closest('form.upload-form');
    form.find('input[type=file]').trigger('click');
  });

  // auto submit form: upload file after selection
  $('form.upload-form input[type=file]').on('change', function() {
    $('.spinner').show();
    const form = $(this).closest('form.upload-form');
    form.trigger('submit');
  });

});